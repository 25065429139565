.center{
    text-align: center;
    display: block;
}

.tab-content {
    background-color: lightslategray;
}


.designer-container {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    background: pink;
}

.designer-wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    /* the bottom margin is the negative value of the footer's height */
    background: yellow;
}



.designer-footer
{
    display: block;
    width: 100%;
    height: 300px;
    overflow-y: hidden;  
    background: darkgray;
    padding: 15px; 
}

.designer-footer .col-lg-1 .col-md-1 .col-xs-3 {
    display: inline-block;
}
.row-fluid{
     white-space: nowrap;
}
.row-fluid .page{
     display: inline-block;
}

.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}
.bs-callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
}
.bs-callout p:last-child {
    margin-bottom: 0;
}
.bs-callout code {
    border-radius: 3px;
}
.bs-callout+.bs-callout {
    margin-top: -5px;
}
.bs-callout-default {
    border-left-color: #777;
}
.bs-callout-default h4 {
    color: #777;
}
.bs-callout-primary {
    border-left-color: #428bca;
}
.bs-callout-primary h4 {
    color: #428bca;
}
.bs-callout-success {
    border-left-color: #5cb85c;
}
.bs-callout-success h4 {
    color: #5cb85c;
}
.bs-callout-danger {
    border-left-color: #d9534f;
}
.bs-callout-danger h4 {
    color: #d9534f;
}
.bs-callout-warning {
    border-left-color: #f0ad4e;
}
.bs-callout-warning h4 {
    color: #f0ad4e;
}
.bs-callout-info {
    border-left-color: #5bc0de;
}
.bs-callout-info h4 {
    color: #5bc0de;
}

.map {
      height: 100%;
    }